<div *ngIf="showAnnouncement"
[@stateAnnouncement]="stateAnnouncement"
     class="announcement">
    <p [innerHtml]="noticeBarText | safe: 'html'">
        {{noticeBarText}}
    </p>
    <button (click)="toggleAnnouncement()"
            class="announcement__close-button">
        <span class="sr-only">Close</span>
    </button>
</div>
<header>
    <div class="container-grid">
        <a class="skip-nav-link"
           href="#main-content"
           tabindex="0"
           (click)="addSmoothScroll()">
            Skip Navigation
        </a>
        <h1>
            <ng-container *ngIf="!mobileMenuIsOpen; else onlyText">
                <a routerLink="">
                    <img src="../assets/img/autoexports-logo.svg"
                         width="160"
                         height="18"
                         alt="Autoexports Logo">
                </a>
            </ng-container>
            <ng-template #onlyText>AutoExports</ng-template>
        </h1>

        <button aria-controls="primary-navigation"
                [ngClass]="{'open': mobileMenuIsOpen }"
                class="mobile-nav-toggle"
                (click)="openMobileMenu()">
            <span></span>
            <span></span>
            <span></span>
            <span class="sr-only">Menu</span>
        </button>

        <nav aria-label="primary">
            <ul id="primary-navigation"
                [ngClass]="{'open': mobileMenuIsOpen}">
                <li>
                    <a routerLink=""
                       (click)="openMobileMenu()">Home</a>
                </li>
                <li>
                    <a routerLink="/pricing"
                       (click)="openMobileMenu()">Pricing</a>
                </li>
                <li>
                    <a routerLink="/about"
                       (click)="openMobileMenu()">About us</a>
                </li>
                <li>
                    <a routerLink="/news"
                       (click)="openMobileMenu()">News</a>
                </li>
                <li class="mobile-menu-items">
                    <a routerLink="/privacypolicy"
                       (click)="openMobileMenu()">Privacy policy</a>
                </li>

                <li class="mobile-menu-items bg-mobile top">
                    <span class="mobile-menu-items__title">Export</span>
                </li>
                <li class="sub-item">
                    <span>Help <span class="sub-item__item"></span></span>
                    <ul class="sub-menu">
                        <li>
                            <a routerLink="/faq"
                               (click)="openMobileMenu()">FAQ</a>
                        </li>
                        <!-- <li>
                            <a href="">Live chat</a>
                        </li> -->
                        <li>
                            <a routerLink="/contact"
                               (click)="openMobileMenu()">Contact us</a>
                        </li>
                    </ul>
                </li>
                <li class="start-export bg-mobile">
                    <button routerLink="/export"
                            (click)="openMobileMenu()"
                            class="primary-button">Start your export</button>
                </li>
                <li class="mobile-menu-items bg-mobile bottom">
                    <a routerLink="/export/resume"
                       (click)="openMobileMenu()">Resume export</a>
                </li>

                <li class="
                       mobile-menu-items">
                    <span class="mobile-menu-items__title">Help</span>
                </li>
                <li class="mobile-menu-items">
                    <a routerLink="/faq"
                       (click)="openMobileMenu()">FAQ</a>
                </li>
                <!-- <li class="mobile-menu-items">
                    <a href="">Live chat</a>
                </li> -->
                <li class="mobile-menu-items">
                    <a routerLink="/contact"
                       (click)="openMobileMenu()">Contact us</a>
                </li>
            </ul>
        </nav>
    </div>
</header>
